import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import HtmlContent from '../components/HtmlContent';
import Layout from '../components/Layout';
import Project from '../components/Project';

const ProjectTemplate = ({ data }) => {
  const { body, fields, frontmatter } = data.project;
  const { title, description, image, url, git, tags } = frontmatter;
  const { slug } = fields;

  return (
    <Layout title={title} description={description} slug={slug}>
      <Project
        title={title}
        description={description}
        image={image}
        url={url}
        git={git}
        tags={tags}
      >
        <HtmlContent>{body}</HtmlContent>
      </Project>
    </Layout>
  );
};

ProjectTemplate.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default ProjectTemplate;

export const projectTemplateQuery = graphql`
  query ProjectTemplate($slug: String!) {
    project: mdx(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        description
        image {
          ...FeaturedImage
        }
        url
        git
        tags
      }
      body
    }
  }
`;
