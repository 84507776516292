import PropTypes from 'prop-types';
import React from 'react';

import propTypes from '../propTypes';
import Header from './Header';
import Icon from './Icon';
import Image from './Image';

/* eslint-disable react/prop-types */
const Description = ({ children }) => children && <p>{children}</p>;

const Url = ({ children }) =>
  children && (
    <li>
      🌐 <a href={children}>{children}</a>
    </li>
  );

const Git = ({ urls }) =>
  (urls || []).map(url => (
    <li key={url}>
      <Icon icon={['fab', 'git-alt']} />
      <a href={url}>{url}</a>
    </li>
  ));
/* eslint-enable react/prop-types */

class Project extends React.PureComponent {
  renderDescription() {
    const { description } = this.props;
    return description && <p>{description}</p>;
  }

  renderUrl() {
    const { url } = this.props;

    return (
      url && (
        <li>
          🌐 <a href={url}>{url}</a>
        </li>
      )
    );
  }

  render() {
    const { children, title, image, description, url, git, tags } = this.props;

    return (
      <article className="project">
        <Header title={title} tags={tags} />
        <Description>{description}</Description>
        <ul className="is-unstyled">
          <Url>{url}</Url>
          <Git urls={git} />
        </ul>
        <Image source={image} />
        {children}
      </article>
    );
  }
}

Project.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  image: propTypes.image,
  url: PropTypes.string,
  git: PropTypes.arrayOf(PropTypes.string),
  description: PropTypes.string,
  tags: propTypes.tags,
};

Project.defaultProps = {
  children: null,
  image: '',
  url: '',
  git: [],
  description: '',
  tags: [],
};

export default Project;
